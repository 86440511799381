import Page from "@/models/Page"
import ValuationRequest from "@/models/ValuationRequest"
import Vue from "vue"

export enum ValuationRequestStatus {
  STATUS_PENDING,
  STATUS_COMPLETE,
  STATUS_CANCEL,
  STATUS_FAIL,
  STATUS_FINAL,
  STATUS_DISABL,
  STATUS_INPROGRESS,
}

export interface ValuationRequestForm {
  id: number
  requestorBranchSelectId: number
  requestorId: number
  address: string
  area: string
  city: string | undefined
  state: string | undefined
  requestorName: string | undefined
  customerName: string | undefined
  customerContact: string | undefined
  bankReferenceId: string | undefined
  code: string | undefined
  tenureExpiryDateV: string | undefined
  priceRequested: number | undefined
  requestType: number | undefined
  stateTypeId: number | undefined
  landAreaSqft: number
  builtupAreaSqft: number
  typeOfProperty: number | undefined
  branchId: number | undefined
  vrLevel: number | undefined
  position: number | undefined
  tenure: number | undefined
  bumiStatus: number | undefined
  slaDays: number | undefined
}

export interface RequestType {
  id: number
  desc: string
  code: string
}

export interface Valuer {
  id: number
  created?: any
  modified?: any
  code: string
  name: string
  slug: string
  orgId: number
  userId: number
  licensed: string
  signature?: any
  shortName: string
  licensedNo: string
  mobileNo: string
  officeNo: string
  signatureText: string
  svaluer: string
  createdV: string
  modifiedV: string
}

export default class RestValuationRequest {

  public valuer() {
    return Vue.$axios.get<Valuer[]>(`valuationRequest/valuer`)
  }

  public updateValuer(request: Partial<ValuationRequestForm>) {
    return Vue.$axios.post<ValuationRequest>("valuationRequest/updateValuer", request)
  }

  public list(pageNo: number) {
    return Vue.$axios.get<Page<ValuationRequest>>(`valuationRequest/${pageNo}`)
  }
  public create(request: Partial<ValuationRequestForm>) {
    return Vue.$axios.post<ValuationRequest>("valuationRequest/create", request)
  }

  public detail(id: number) {
    return Vue.$axios.get<ValuationRequest>(`valuationRequest/detail/${id}`)
  }

  public status() {
    return Vue.$axios.get<ValuationRequest>("valuationRequest/status")
  }

  public requestType() {
    return Vue.$axios.get<RequestType[]>("valuationRequest/requestType")
  }

  public updateRequestor(request: Partial<ValuationRequestForm>) {
    return Vue.$axios.post<ValuationRequest>(
      "valuationRequest/updateRequestor",
      request
    )
  }

  public updatePropertyDetail(request: Partial<ValuationRequestForm>) {
    return Vue.$axios.post<ValuationRequest>(
      "valuationRequest/updatePropertyDetail",
      request
    )
  }

  public updateCustomer(request: Partial<ValuationRequestForm>) {
    return Vue.$axios.post<ValuationRequest>(
      "valuationRequest/updateCustomer",
      request
    )
  }
}
